define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/grants-policies/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base", "securitytrax-ember-ui/utils/obj-helpers", "lodash", "ember-concurrency"], function (_exports, _permissionsBase, _objHelpers, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permissionsBase.default.extend({
    permissionsService: Ember.inject.service(),
    router: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    packageSelectEvent: Ember.inject.service('events/package-select-event'),
    rowListEvent: Ember.inject.service('events/row-list-event'),
    stFiltersService: Ember.inject.service(),
    updatedPoliciesList: undefined,
    newPoliciesList: undefined,
    deletePoliciesList: undefined,
    hasHiddenParents: false,
    showHiddenParents: false,
    relationMap: undefined,
    hiddenRows: undefined,
    attributesLoaded: false,
    totalCheckedInitially: 0,
    unsavedGrantsEdits: false,
    unsavedPoliciesEdits: false,

    init() {
      this._super(...arguments);

      this.rowListEvent.on('update-row-list', this, '_callback');
      (0, _objHelpers.initializeState)(this, {
        updatedPoliciesList: [],
        newPoliciesList: [],
        deletePoliciesList: [],
        relationMap: {
          sections: {},
          rows: {}
        },
        hiddenRows: [],
        priorityMapping: {
          calendar_event: ['calendar_events'],
          customer: ['customers'],
          equipment_and_inventory: ['equipment'],
          lead: ['leads'],
          user: ['users']
        }
      });
      this.model.resourceGrantsPolicies.filteredItems.forEach(resourceType => {
        this.relationMap.sections[resourceType.resourceTypeId] = resourceType;
        var parentId = null;
        resourceType.items.forEach(subResourceType => {
          Ember.set(subResourceType, 'ogSelected', [...subResourceType.resourceGrantsSelected]);
          this.totalCheckedInitially += subResourceType.ogSelected.length;
          this.relationMap.rows[subResourceType.resourceTypeId] = {
            sectionId: resourceType.resourceTypeId,
            data: subResourceType,
            parentId
          };

          if (subResourceType.isParent) {
            parentId = subResourceType.resourceTypeId;
          }
        });
      });
    },

    _callback(itemToAdd, listToUpdate) {
      var theListToUpdate = Ember.get(this, listToUpdate);

      if (!itemToAdd._deleted) {
        theListToUpdate.push(itemToAdd);
      }

      Ember.set(this, 'unsavedPoliciesEdits', this.updatedPoliciesList.length > 0 || this.newPoliciesList.filter(item => !item._deleted).length > 0 || this.deletePoliciesList.length > 0);
    },

    willDestroy() {
      this.rowListEvent.off('update-row-list', this, '_callback');
    },

    setupTreeLines() {
      var lineStack = [-1];

      for (var index = this.model.resourceGrantsPolicies.filteredItems.length - 1; index >= 0; index--) {
        var resourceType = this.model.resourceGrantsPolicies.filteredItems[index];

        for (var subIndex = resourceType.filteredItems.length - 1; subIndex >= 0; subIndex--) {
          var subResourceType = resourceType.filteredItems[subIndex];
          subResourceType.additionalClasses = [];
          subResourceType.isLastOfLevel = false;

          if (subResourceType.treeLevel > lineStack.at(-1)) {
            lineStack.push(subResourceType.treeLevel);
            subResourceType.isLastOfLevel = true;
          } else if (subResourceType.treeLevel < lineStack.at(-1)) {
            lineStack.pop();

            if (subResourceType.treeLevel !== lineStack.at(-1)) {
              lineStack.push(subResourceType.treeLevel);
              subResourceType.isLastOfLevel = true;
            }
          }

          for (var line_i = 1; line_i < lineStack.length - 1; line_i++) {
            var treeLevel = lineStack[line_i];

            if (treeLevel !== 0) {
              subResourceType.additionalClasses.push('branch-line-' + treeLevel);
            }
          }
        }
      }
    },

    attributes: Ember.computed(function () {
      return this.getAllAttributesList.perform();
    }),
    getAllAttributesList: (0, _emberConcurrency.task)(function* () {
      var attributes = {};
      var promises = [];
      this.model.resourceGrantsPolicies.filteredItems.forEach(resourceType => {
        resourceType.items.forEach(subResourceType => {
          promises.push(this.getResourceTypeAttributesList.perform(subResourceType.resourceType));
        });
      });
      var resolvedPromises = yield (0, _emberConcurrency.all)(promises);
      resolvedPromises.forEach(value => {
        Object.assign(attributes, value);
      });
      Ember.set(this, 'attributesLoaded', true);
      return attributes;
    }),
    isAdminGroup: Ember.computed('model.group.id', function () {
      return this.model.group.id === '1000';
    }),
    saveGrantsPolicies: (0, _emberConcurrency.task)(function* () {
      try {
        var group = this.get('model.group');
        var allItems = this.get('model.allGrantsPolicies');
        var allItemsArray = Object.keys(allItems).map(function (key) {
          return allItems[key];
        });
        var allAddedGrants = [];
        var allRemovedGrants = []; // SAVE all grants. This is complete list replace, not additive
        // PERF TODO: convert to a hash function or something more efficient?

        allItemsArray.forEach(section => {
          var sectionItems = section.items;
          sectionItems.forEach(sub => {
            sub.items.forEach(i => {
              allAddedGrants = _lodash.default.union(allAddedGrants, i.addedGrants);
              allRemovedGrants = _lodash.default.union(allRemovedGrants, i.removedGrants);
            });
          });
        });
        yield (0, _emberConcurrency.hash)({
          updatePolicies: this.addEditDeletePolicies.perform(group),
          addGrants: allAddedGrants.length > 0 ? group.updateRelationship('POST', 'resource_type_grants', allAddedGrants, 'resource_type_grants') : null,
          removeGrants: allRemovedGrants.length > 0 ? group.updateRelationship('DELETE', 'resource_type_grants', allRemovedGrants, 'resource_type_grants') : null
        });
        yield group.reload(); // Ensure permissions service will refetch all grants and policies before transitioning back

        Ember.set(this.permissionsService, '_currentGroup', null);
        this.router.transitionTo('administration.human-resources.permissions.group-details', group);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      } catch (err) {
        if (err !== '') {
          // Validation error on policies
          this.stNotificationManager.addErrorMessages(err);
          (false && Ember.warn('HR group save grants error: ' + err, {
            id: 'multiStepForm.forms.administration.permissions.grants-policies.saveGrantsPolicies'
          }));
        }

        throw err;
      }
    }),
    addEditDeletePolicies: (0, _emberConcurrency.task)(function* (group) {
      // TODO: DELETE, CREATE, PATCH POLICIES similar to RESOURCE-TYPE-POLICIES-REUSABLE MIXIN, but currently need
      // to keep both, as user policies still uses the mixin, but in a different way than group policies
      // Filter out new policies that were deleted before save
      this.newPoliciesList = this.newPoliciesList.filter(a => !a.get('_deleted')); // VALIDATIONS BEFORE ADD/EDIT

      var fullList = this.updatedPoliciesList.concat(this.newPoliciesList);
      var formattedObjects = [];
      fullList.forEach((item, index) => {
        if (index < fullList.get('length') - 1) {
          formattedObjects.pushObject(Ember.Object.create({
            model: {
              policy: item.get('resourceTypePolicy.id'),
              operator: item.get('operator'),
              conditionRight: item.get('conditionRight.value'),
              item: item
            },
            filePath: 'policies',
            extraValidationData: {
              overrideValidationKey: index + '-policies'
            }
          }));
        }
      });
      var allSaves = [this.multiStepForm.validateList.perform(formattedObjects)]; // DELETE POLICIES

      for (var q = 0; q < this.deletePoliciesList.get('length'); q++) {
        allSaves.push(this.deletePolicyTask.perform(this.deletePoliciesList[q]));
      } // PATCH/UPDATE


      for (var _q = 0; _q < this.updatedPoliciesList.get('length'); _q++) {
        var lineItem = this.updatedPoliciesList.objectAt(_q);

        if (!this.deletePoliciesList[lineItem.get('id')]) {
          allSaves.push(lineItem.save());
        }
      } // POST/CREATE POLICIES


      for (var _q2 = 0; _q2 < this.newPoliciesList.get('length'); _q2++) {
        var itemToCreate = this.newPoliciesList.objectAt(_q2);

        if (itemToCreate.get('resourceTypePolicy.id') != null) {
          // Don't send POST for empty policy
          allSaves.push(this.createPolicyTask.perform(itemToCreate, group));
        }
      }

      yield (0, _emberConcurrency.all)(allSaves);
    }),
    deletePolicyTask: (0, _emberConcurrency.task)(function* (itemToDelete) {
      var toDelete = yield this.store.findRecord('groupResourceTypePolicy', itemToDelete.get('id'), {
        backgroundReload: false
      });
      yield toDelete.destroyRecordWithRollbackTask.perform();
    }),
    createPolicyTask: (0, _emberConcurrency.task)(function* (itemToCreate, group) {
      var resourceTypePolicy = itemToCreate.get('resourceTypePolicy.id');
      var newPolicyObject = {
        group: group,
        conditionRight: itemToCreate.get('conditionRight'),
        operator: itemToCreate.get('operator'),
        resourceTypePolicy
      };
      var newResourceTypePolicy = yield this.store.createRecord('groupResourceTypePolicy', newPolicyObject);
      yield newResourceTypePolicy.save();
    }),
    updateTotalCheckedTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        all: this.get('model.resourceGrantsPolicies'),
        allItems: this.get('model.resourceGrantsPolicies.filteredItems')
      });
      var sumOfAllChecked = 0;
      var sumOfVisibleChecked = 0;
      var totalVisibleGrants = 0;
      hashedData.allItems.forEach(resourceType => {
        resourceType.items.forEach(subResourceType => {
          sumOfAllChecked += subResourceType.resourceGrantsSelected.length;

          if ((subResourceType.meetsFilterCriteria || subResourceType.someChildrenMeetFilterCriteria) && !subResourceType.isHidden) {
            sumOfVisibleChecked += subResourceType.resourceGrantsSelected.length;
            totalVisibleGrants += subResourceType.resourceGrants.get('length');
          }
        });
      });

      if (sumOfVisibleChecked === totalVisibleGrants) {
        Ember.set(this, 'allChecked', true);
        Ember.set(this, 'allIndeterminate', false);
      } else if (sumOfVisibleChecked === 0) {
        Ember.set(this, 'allChecked', false);
        Ember.set(this, 'allIndeterminate', false);
      } else {
        Ember.set(this, 'allChecked', false);
        Ember.set(this, 'allIndeterminate', true);
      }

      Ember.set(this, 'unsavedGrantsEdits', this.totalCheckedInitially != sumOfAllChecked);
      Ember.set(hashedData.all, 'totalChecked', sumOfVisibleChecked);
    }),
    cancelAndGoBack: (0, _emberConcurrency.task)(function* () {
      try {
        var group = this.get('model.group');
        this.permissionsService.set('_currentGroup', null);
        this.permissionsService.set('_groupResourceTypePolicies', null);
        yield this.store.unloadAll('groupResourceTypePolicy'); // Clear these from local cache

        this.router.transitionTo('administration.human-resources.permissions.group-details', group);
        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        (false && Ember.warn('Error canceling or going back to group details: ' + err, {
          id: 'multiStepForm.forms.administration.permissions.grants-policies.cancelAndGoBack'
        }));
      }
    }),
    filteredResourceGrantsPolicies: Ember.computed(function () {
      return this.filteredResourceGrantsPoliciesTask.perform();
    }),
    filteredResourceGrantsPoliciesTask: (0, _emberConcurrency.task)(function* () {
      var _this = this;

      var section_key = this.model.resourceGrantsPolicies.sectionKey === 'no_section_key' ? 'null' : this.model.resourceGrantsPolicies.sectionKey;
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('permissionsFilter', ["section_key = '".concat(section_key, "'")], {
        attribute: true
      });
      var resourceGrantsPolicies = yield this.store.query('resourceType', {
        filter: newFilters
      });
      Ember.set(this, 'hasRows', resourceGrantsPolicies.get('length') > 0); // Reset filteredItems and flags

      this.model.resourceGrantsPolicies.filteredItems.forEach(resourceType => {
        Ember.set(resourceType, 'filteredItems', []);
        resourceType.items.forEach(item => {
          item.meetsFilterCriteria = false;
          item.isHidden = false;
          item.someChildrenMeetFilterCriteria = false;
          item.groupingDivOpen = false;
        });
      });
      yield (0, _emberConcurrency.waitForProperty)(this, 'attributesLoaded', true); // set item flag for filtered results

      resourceGrantsPolicies.forEach(resourceType => {
        var relationData = this.relationMap.rows[resourceType.get('id')];

        if (!relationData) {
          return;
        }

        var passesAttributeFilter = true;

        if (this.attribute) {
          var childResourceAttributes = relationData.data.resourceType.attributes;
          var combinedChildResourceAttributes = childResourceAttributes.attributes.concat(childResourceAttributes.metaAttributes);
          passesAttributeFilter = combinedChildResourceAttributes.some(childAttribute => {
            return childAttribute.includes(Ember.String.underscore(this.attribute.toLowerCase()));
          });
        }

        if (passesAttributeFilter) {
          relationData.data.meetsFilterCriteria = true;
          var curParentId = relationData.parentId;

          while (curParentId !== null) {
            var parent = this.relationMap.rows[curParentId];
            parent.data.someChildrenMeetFilterCriteria = true;
            curParentId = parent.parentId;
          }
        }
      });
      this.model.resourceGrantsPolicies.filteredItems.forEach(resourceType => {
        resourceType.items.forEach(subResourceType => {
          if (subResourceType.meetsFilterCriteria || subResourceType.someChildrenMeetFilterCriteria) {
            resourceType.filteredItems.push(subResourceType);

            if (!subResourceType.meetsFilterCriteria && subResourceType.isParent) {
              subResourceType.isHidden = true;
              this.hasHiddenParents = true;
              this.hiddenRows.push(subResourceType);
            }
          }
        });
      });
      this.model.resourceGrantsPolicies.filteredItems.sort((itemA, itemB) => itemA.name.localeCompare(itemB.name));
      var sectionKey = this.model.resourceGrantsPolicies.sectionKey;

      if (this.priorityMapping[sectionKey]) {
        var _loop = function (i) {
          var rt = _this.priorityMapping[sectionKey][i];

          var index = _this.model.resourceGrantsPolicies.filteredItems.findIndex(item => {
            return item.resourceTypeId == rt;
          });

          if (index !== -1) {
            _this.model.resourceGrantsPolicies.filteredItems.splice(i, 0, _this.model.resourceGrantsPolicies.filteredItems.splice(index, 1)[0]);
          }
        };

        for (var i = 0; i < this.priorityMapping[sectionKey].length; i++) {
          _loop(i);
        }
      }

      this.setupTreeLines();
      yield this.updateTotalCheckedTask.perform();
      return this.model.resourceGrantsPolicies.filteredItems;
    }),
    actions: {
      bulkAddAllGrants(section) {
        var items = _lodash.default.get(section, 'filteredItems', []);

        items.forEach(group => {
          var groupItems = group.filteredItems;
          groupItems.forEach(resource => {
            if (!resource.isHidden) {
              Ember.set(group, 'indeterminate', false);
              Ember.set(group, 'bulkEdit', true);

              if (this.allChecked) {
                var resourceGrants = resource.resourceGrants.toArray();
                Ember.set(resource, 'removedGrants', []);
                Ember.set(resource, 'addedGrants', resourceGrants.filter(grant => {
                  return !resource.ogSelected.includes(grant.get('id'));
                }).map(item => {
                  return item.id;
                }));
                Ember.set(resource, 'resourceGrantsSelected', resourceGrants.map(item => {
                  return item.id;
                }));
                var resourceType = resource.resourceType;
                resourceType.set('bulkCheck', true);
              } else {
                Ember.set(resource, 'removedGrants', resource.ogSelected);
                Ember.set(resource, 'addedGrants', []);
                Ember.set(resource, 'resourceGrantsSelected', []);
                var _resourceType = resource.resourceType;
                Ember.set(_resourceType, 'bulkCheck', false);
              }
            }
          });
        });
        this.packageSelectEvent.trigger('update-selected-array');
        this.updateTotalCheckedTask.perform();
      },

      toggleHiddenParents() {
        this.toggleProperty('showHiddenParents');
        this.hiddenRows.forEach(row => {
          Ember.set(row, 'isHidden', !this.showHiddenParents);
        });
        this.updateTotalCheckedTask.perform();
      },

      clearFilter() {
        Ember.setProperties(this, {
          resourceType: undefined,
          grant: undefined,
          attribute: undefined,
          showHiddenParents: false,
          hasHiddenParents: false,
          hiddenRows: []
        });
        Ember.notifyPropertyChange(this, 'filteredResourceGrantsPolicies');
      },

      filterItems() {
        Ember.notifyPropertyChange(this, 'filteredResourceGrantsPolicies');
      },

      expandAll() {
        this.model.resourceGrantsPolicies.filteredItems.forEach(resourceType => {
          resourceType.filteredItems.forEach(subResourceType => {
            Ember.set(subResourceType, 'groupingDivOpen', true);
          });
        });
      }

    }
  });

  _exports.default = _default;
});