define("securitytrax-ember-ui/models/base_models/location-udf", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    value: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    adminUdf: (0, _model.belongsTo)('admin-udf', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    customerContact: (0, _model.belongsTo)('customer-contact', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    customerEquipment: (0, _model.belongsTo)('customer-equipment', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    equipment: (0, _model.belongsTo)('equipment', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    location: (0, _model.belongsTo)('location', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    techSchedule: (0, _model.belongsTo)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    leadCompany: (0, _model.belongsTo)('lead-company', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    customerAtRisk: (0, _model.belongsTo)('customer-at-risk', {
      'readOnly': false,
      'readOnlyUpdate': true
    })
  });

  _exports.default = _default;
});