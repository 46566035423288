define("securitytrax-ember-ui/models/base_models/group", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    about: (0, _model.attr)('string', {
      'readOnly': false
    }),
    assignable: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    users: (0, _model.hasMany)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'group'
    }),
    roles: (0, _model.hasMany)('role', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    resourceTypeGrants: (0, _model.hasMany)('resource-type-grant', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    groupResourceTypePolicies: (0, _model.hasMany)('group-resource-type-policy', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});