define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/invoice/payments/confirm-refund-void/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base", "ember-concurrency", "jquery"], function (_exports, _base, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ConfirmRefundVoidPayment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* (payment, action, data) {
    try {
      var hashedData = yield (0, _emberConcurrency.hash)({
        modifyPayment: this.paymentService.modifyPayment(payment, action, data),
        customerPayments: this.args.customer.get('customerPayments')
      });

      if (this.args.singlePage) {
        this.args.transitionBackToParent();
        this.stNotificationManager.addSuccessKey('pages.accounting.payments.payment_modified');
      } else {
        this.args.multiStepForm.currentStepNameKeySetVal('listPayments::payments');
        this.stNotificationManager.addSuccessKey('pages.accounting.payments.payment_modified', 'modal');
        this.args.customer.invoices.reload();
      }

      hashedData.customerPayments.reload();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class ConfirmRefundVoidPayment extends _base.default {
    get hiddenProgressBarSteps() {
      return ['gettingStarted'];
    }

    get disabledProgressBarSteps() {
      return [];
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "paymentService", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "refundAmount", _descriptor4, this);

      _initializerDefineProperty(this, "modifyPaymentTask", _descriptor5, this);

      this.refundAmount = this.args.multiStepForm.currentStepData.get('currentVoidingOrRefundingCustomerPayment.amount');
    }

    get isRefund() {
      return this.args.multiStepForm.currentStepData.get('currentVoidingOrRefundingCustomerPayment.processingStatus').toLowerCase() === 'complete';
    }

    refund() {
      //TODO: This may need a refactor to use .validate() if Partial Refund is a thing. If its not a thing, this may be removed completely and just refund amount
      if (isNaN(this.refundAmount) || !this.refundAmount) {
        var required = this.intl.t('pages.customers.accounting.customer_payments.amount_to_refund.error');
        (0, _jquery.default)('.input-name-amount .error-message').text(required).show();
      } else {
        (0, _jquery.default)('.input-name-amount .error-message').hide();
        return this.modifyPaymentTask.perform(this.args.multiStepForm.currentStepData.get('currentVoidingOrRefundingCustomerPayment'), 'refund', {
          amount: this.refundAmount
        });
      }
    }

    void() {
      return this.modifyPaymentTask.perform(this.args.multiStepForm.currentStepData.get('currentVoidingOrRefundingCustomerPayment'), 'void', {});
    }

    cancel() {
      this.args.multiStepForm.currentStepNameKeySetVal(this.args.singlePage ? 'viewProcessingInformation' : 'listPayments::viewProcessingInformation');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "paymentService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "refundAmount", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modifyPaymentTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refund", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "refund"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "void", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "void"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = ConfirmRefundVoidPayment;
});