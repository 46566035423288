define("securitytrax-ember-ui/models/base_models/customer-at-risk", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    isClosed: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isResolved: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    description: (0, _model.attr)('string', {
      'readOnly': false
    }),
    amountOwed: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    amountCollected: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    amountExpected: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    amountCredited: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    beginDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    endDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    amountCollectedClearedDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    followUpDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    assignedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    resolvedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    closedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'customerAtRisks'
    }),
    resolutionCustomer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    assignedUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    saveUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    resolvedByUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    closedByUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerAtRiskReason: (0, _model.belongsTo)('customer-at-risk-reason', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerAtRiskReasonCause: (0, _model.belongsTo)('customer-at-risk-reason-cause', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerAtRiskResolution: (0, _model.belongsTo)('customer-at-risk-resolution', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerAtRiskCreditReason: (0, _model.belongsTo)('customer-at-risk-credit-reason', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerAtRiskNotes: (0, _model.hasMany)('customer-at-risk-note', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    udfs: (0, _model.hasMany)('udf', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});