define("securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DyTRZHa9",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,6],[[30,[36,5],[\"groups.view_tag\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"html/st-tags\",[],[[\"@title\",\"@selectedTags\",\"@parentModel\",\"@isFulfilled\",\"@toggleTagView\",\"@deleteFromName\",\"@tagObjectType\"],[[30,[36,3],[\"pages.administration.human_resources.apply_group\"],null],[34,0,[\"tags\"]],[34,0],[34,0,[\"tags\",\"isFulfilled\"]],[30,[36,4],[[32,0],\"toggleTagView\"],null],[34,0,[\"flname\"]],\"group\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],null,[[\"formSteps\",\"formOriginalModels\",\"usesModal\",\"currentStepNameKey\",\"formStarted\"],[[35,8],[35,7],false,\"editGroup\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"addEditGroup\",\"multiStepForm\",\"formMode\"],[[35,0],[32,1],[35,2]]]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[\"users.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[\"multi-step-form/forms/administration/permissions/group-users\"],[[\"addEditGroup\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"groupFormMode\",\"t\",\"action\",\"has-grants\",\"if\",\"formOriginalModels\",\"addEditGroupSteps\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/template.hbs"
    }
  });

  _exports.default = _default;
});