define("securitytrax-ember-ui/models/consumer-financing-lender", ["exports", "securitytrax-ember-ui/models/base_models/consumer-financing-lender", "ember-concurrency"], function (_exports, _consumerFinancingLender, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _consumerFinancingLender.default.extend({
    //Asynchronous method for checking connection to a consumer financing lender.
    isConnected: Ember.computed('consumerFinancingLenderTypeId', function () {
      return this.isConnectedTask.perform();
    }),
    isConnectedTask: (0, _emberConcurrency.task)(function* () {
      var isConnected = false;

      switch (this.consumerFinancingLenderType.get('id')) {
        case '1': //Smart Home Pay

        case '4':
          //Smart Home Pay LOS
          try {
            yield this.invokeAction('test_connection', {
              verb: 'POST',
              data: {
                data: {}
              }
            });
            isConnected = true;
          } catch (err) {
            isConnected = false;
          }

          break;

        default:
          isConnected = false;
      }

      return isConnected;
    })
  });

  _exports.default = _default;
});