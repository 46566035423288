define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/invoice/payments/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base"], function (_exports, _emberConcurrency, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoicePayments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('events/invoice-payments-events'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = (0, _emberConcurrency.task)(function* () {
    this.invoicePaymentsEvent.on('reload-list', this, '_callback');
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.customerInvoicePayments = yield this.getCustomerInvoicePayments.perform();

    if (this.formMode !== 'view') {
      this.args.multiStepForm.registerStepData('listPayments', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        hideButton: false,
        click: buttonPromise => {
          return this.applyCustomerPayments.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary invoice-payment-save-button'
      }]);
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var otherCustomerPayments = yield this.store.query('customerPayment', {
      filter: "customer = ".concat(this.args.customer.get('id'), " and (processing_status != 'failed' or processing_status = null)"),
      include: 'customer_invoice_payments',
      sort: '-payment_date,-id'
    });
    var validCustomerPaymentsForCurrentInvoice = otherCustomerPayments.filter(payment => {
      // Check to see if customerPayment has any unused balance
      var totalAmountOnCustomerPayment = +payment.amount;
      var combinedTotalAmountOnCustomerInvoicePayments = 0;
      payment.customerInvoicePayments.forEach(invoicePayment => {
        combinedTotalAmountOnCustomerInvoicePayments += +invoicePayment.amountUsed;
      });
      var hasRemainingBalance = totalAmountOnCustomerPayment > combinedTotalAmountOnCustomerInvoicePayments; // Also check to see if any amount of the customerPayment has been applied to this invoice

      var customerInvoicePaymentUsedOnCurrentInvoice = payment.customerInvoicePayments.find(invoicePayment => {
        // Do stupid ID checks cause Mirage is stupid
        return (JSON.parse(invoicePayment.id).invoice_id ? JSON.parse(invoicePayment.id).invoice_id : invoicePayment.id) === this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.id');
      });

      if (customerInvoicePaymentUsedOnCurrentInvoice) {
        Ember.set(payment, '_applyToInvoice', true);
        Ember.set(payment, '_amountOnInvoice', customerInvoicePaymentUsedOnCurrentInvoice.get('amountUsed'));
      } else {
        Ember.set(payment, '_applyToInvoice', false);
        Ember.set(payment, '_amountOnInvoice', 0);
      }

      Ember.set(payment, '_amountAvailable', totalAmountOnCustomerPayment - combinedTotalAmountOnCustomerInvoicePayments);
      return hasRemainingBalance || customerInvoicePaymentUsedOnCurrentInvoice;
    });
    var promiseArray = [];
    validCustomerPaymentsForCurrentInvoice.forEach(payment => {
      promiseArray.push(payment.get('customerBilling.title'));
    });
    var resolvedPromiseArray = yield (0, _emberConcurrency.all)(promiseArray);
    validCustomerPaymentsForCurrentInvoice.forEach((payment, index) => {
      Ember.set(payment, '_resolvedTitle', resolvedPromiseArray[index] ? resolvedPromiseArray[index] : payment.get('customerBillingOrPaymentTypeLabel'));
    });
    return validCustomerPaymentsForCurrentInvoice;
  }), _dec14 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var newCustomerInvoicePayment;
    var customerInvoicePaymentUsedOnCurrentInvoice;
    var promiseArray = Ember.A();
    var invoicePayments = this.customerInvoicePayments;

    try {
      invoicePayments.forEach(payment => {
        if (payment.get('_dirty')) {
          customerInvoicePaymentUsedOnCurrentInvoice = payment.customerInvoicePayments.find(invoicePayment => {
            return JSON.parse(invoicePayment.id).invoice_id === this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.id');
          });

          if (customerInvoicePaymentUsedOnCurrentInvoice) {
            if (!payment.get('_applyToInvoice')) {
              promiseArray.push(customerInvoicePaymentUsedOnCurrentInvoice.destroyRecordWithRollbackTask.perform());
            } else {
              Ember.set(customerInvoicePaymentUsedOnCurrentInvoice, 'amountUsed', payment.get('_amountOnInvoice'));
              promiseArray.push(customerInvoicePaymentUsedOnCurrentInvoice.save());
            }
          } else {
            if (payment.get('_applyToInvoice') && payment.get('_amountOnInvoice') > 0) {
              newCustomerInvoicePayment = this.store.createRecord('customerInvoicePayment', {
                amountUsed: payment.get('_amountOnInvoice'),
                customerPayment: payment,
                invoice: this.args.multiStepForm.formOriginalModelsGetVal('invoice.model')
              });
              promiseArray.push(newCustomerInvoicePayment.save());
            }
          }
        }
      });
      yield (0, _emberConcurrency.all)(promiseArray);
      this.customerInvoicePayments = yield this.getCustomerInvoicePayments.perform();
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        this.args.multiStepForm.formOriginalModelsGetVal('invoice.model').reload();
        Ember.notifyPropertyChange(this, 'customerInvoicePayments');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });

      if (newCustomerInvoicePayment) {
        newCustomerInvoicePayment.rollbackAttributes();
      }

      throw err;
    }
  }), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = (_temp = class InvoicePayments extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "invoiceService", _descriptor6, this);

      _initializerDefineProperty(this, "invoicePaymentsEvent", _descriptor7, this);

      _initializerDefineProperty(this, "customerInvoicePayments", _descriptor8, this);

      _initializerDefineProperty(this, "openMenuId", _descriptor9, this);

      _initializerDefineProperty(this, "dynamicMenuItems", _descriptor10, this);

      _initializerDefineProperty(this, "actionsMenuIsOpen", _descriptor11, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor12, this);

      _initializerDefineProperty(this, "getCustomerInvoicePayments", _descriptor13, this);

      _initializerDefineProperty(this, "applyCustomerPayments", _descriptor14, this);

      this.initFormTask.perform();
    }

    get hiddenProgressBarSteps() {
      var hiddenArray = ['confirmRefundVoid', 'gettingStarted', 'viewProcessingInformation', 'history', 'editManualPayment'];
      return hiddenArray;
    }

    get disabledProgressBarSteps() {
      return [];
    }

    willDestroy() {
      this.invoicePaymentsEvent.off('reload-list', this, '_callback');
    }

    _callback() {
      this.customerInvoicePayments = this.getCustomerInvoicePayments.perform();
    }

    markAsDirty(row) {
      var payments = this.customerInvoicePayments;
      var payment = payments.find(payment => payment.id === row.id);

      if (payment) {
        Ember.set(payment, '_dirty', true);
      }
    }

    setAmountOnInvoice(row) {
      var autoFilledAmount = this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.due_amount') <= row.get('_amountAvailable') ? this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.due_amount') : row.get('_amountAvailable');
      var payments = this.customerInvoicePayments;
      var payment = payments.find(payment => payment.id === row.id);

      if (payment) {
        Ember.set(payment, '_amountOnInvoice', autoFilledAmount);
      }
    }

    openRow(row) {
      this.args.setCustomerPayment(row);

      if (row.get('authcode')) {
        Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'listPayments::viewProcessingInformation');
      } else {
        Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'listPayments::editManualPayment');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoiceService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "invoicePaymentsEvent", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "customerInvoicePayments", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "openMenuId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dynamicMenuItems", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "actionsMenuIsOpen", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "getCustomerInvoicePayments", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "applyCustomerPayments", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "markAsDirty", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "markAsDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setAmountOnInvoice", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setAmountOnInvoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openRow", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "openRow"), _class.prototype)), _class));
  _exports.default = InvoicePayments;
});