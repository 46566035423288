define("securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._tracked, _dec2 = Ember._action, (_obj = {
    tagName: '',
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    addEditGroup: undefined,
    groupFormMode: 'view',
    tagViewCollapsed: true,
    currentRoute: Ember.computed('router.currentRouteName', function () {
      var route = this.router;
      return route.currentRouteName;
    }),
    setupFormTask: (0, _emberConcurrency.task)(function* () {
      var grants = this.grants;
      var canModify = yield grants.get('getIndividualGrantAsync').perform('groups.modify');
      this.set('groupFormMode', canModify ? 'edit' : 'view');
    }).on('init'),
    formOriginalModels: Ember.computed('model', 'grants.userGrants', function () {
      return Ember.Object.create({
        group: {
          model: this.model,
          copyRelationMap: {
            resourceTypeGrants: {
              doCopy: this.grants.getIndividualGrant('groups.manage_permissions'),
              doSync: false,
              doSave: false
            }
          }
        }
      });
    }),
    addEditGroupSteps: Ember.computed('grants.userGrants', 'model', function () {
      return Ember.Object.create({
        editGroup: Ember.Object.create({
          stepNameKey: 'editGroup',
          stepComponentPath: 'multi-step-form/forms/administration/permissions/group-details'
        })
      });
    }),

    toggleTagView() {
      this.toggleProperty('tagViewCollapsed');
    }

  }, (_applyDecoratedDescriptor(_obj, "tagViewCollapsed", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "tagViewCollapsed"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "toggleTagView", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleTagView"), _obj)), _obj)));

  _exports.default = _default;
});