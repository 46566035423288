define("securitytrax-ember-ui/utils/grants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.availableGrants = void 0;
  var availableGrants = {
    "account_number_blocks": ["create", "modify", "delete"],
    "activation_fees": ["create", "modify", "delete"],
    "user_admin_settings": ["view", "modify"],
    "adt_monitoring_system_types": ["view", "create", "modify", "delete"],
    "work_order_types": ["create", "modify", "delete"],
    "agency_contacts": ["view", "modify"],
    "customer_birthdates": ["view", "modify"],
    "calendar_events": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag", "view_on_site_info", "create_on_site_info", "modify_on_site_info", "print_appointment_sheet"],
    "calendar_event_confirmation_statuses": ["create", "modify", "delete"],
    "calendar_event_confirmation_types": ["create", "modify", "delete"],
    "calendar_event_confirmations": ["view", "create", "modify", "delete"],
    "calendar_event_detail_types": ["create", "modify", "delete"],
    "calendar_event_files": ["view", "create", "modify", "delete"],
    "calendar_event_reschedules": ["view", "create", "modify", "delete"],
    "calendar_event_tags": ["create", "modify", "delete"],
    "communication_dispositions": ["create", "modify", "delete"],
    "communication_reasons": ["create", "modify", "delete"],
    "companies": ["modify", "perform_equipment_transfer", "company_replenishment", "import_at_risk_adt_pending_cancel", "import_at_risk_adt_aging", "import_at_risk_safe_aging", "manage_account", "view_account", "bulk_cs_account_deactivate_import", "bulk_isp_enable_interactive_lockout_import", "bulk_isp_disable_interactive_lockout_import", "bulk_customer_cancel_import"],
    "company_filings": ["create", "modify", "delete"],
    "credit_bureaus": ["create", "modify", "delete"],
    "customer_at_risks": ["view", "create", "modify", "delete", "manage_status", "manage_assignment"],
    "customer_at_risk_credit_reasons": ["create", "modify", "delete"],
    "customer_at_risk_notes": ["view", "create", "modify", "delete"],
    "customer_at_risk_reason_causes": ["create", "modify", "delete"],
    "customer_at_risk_reasons": ["create", "modify", "delete"],
    "customer_at_risk_resolutions": ["create", "modify", "delete"],
    "customer_at_risk_udfs": ["view", "create", "modify", "delete"],
    "customer_billings": ["view", "view_partial_cc", "create", "modify", "delete"],
    "customer_billing_uses": ["create", "modify", "delete"],
    "customer_cancel_reasons": ["create", "modify", "delete"],
    "customer_credit_checks": ["view", "create", "modify", "delete", "view_print_image"],
    "customer_refund_receipts": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "customer_communications": ["view", "create", "modify", "delete"],
    "customer_contacts": ["view", "create", "modify", "delete"],
    "customer_equipment": ["view", "create", "modify", "delete", "view_tag", "remove_tag", "apply_tag"],
    "customer_files": ["view", "create", "modify", "delete"],
    "customer_invoices": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "customer_note_tags": ["create", "modify", "delete"],
    "customer_notes": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "customer_payments": ["view", "create", "modify", "delete", "process", "refund", "void", "credit"],
    "customer_quotes": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "customer_tags": ["create", "modify", "delete"],
    "customer_messages": ["view", "create"],
    "customer_warranties": ["create", "modify", "delete"],
    "customers": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag", "manage_central_station_integration", "manage_interactive_services_provider_integration", "view_adt_customer_collections_information", "view_adt_customer_funding_information", "central_station_invoke_generic", "modify_account_class", "verify_builder_interactive_services", "duplicate"],
    "dealer_numbers": ["create", "modify", "delete"],
    "discount_affiliates": ["create", "modify", "delete"],
    "dry_run_reasons": ["create", "modify", "delete"],
    "messages": ["create", "modify", "delete"],
    "equipment": ["create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "equipment_ledgers": ["view", "perform_physical_inventory", "view_on_hand"],
    "equipment_return_reasons": ["create", "modify", "delete"],
    "equipment_to_recipients": ["view", "create", "modify", "delete"],
    "equipment_to_recipient_templates": ["create", "modify", "delete"],
    "equipment_to_vendors": ["view", "create", "modify", "delete"],
    "equipment_vendor_to_recipients": ["view", "create", "modify", "delete"],
    "equipment_vendors": ["create", "modify", "delete", "manage_connector"],
    "funder_reasons": ["create", "modify", "delete"],
    "funders": ["view", "create", "modify", "delete"],
    "groups": ["view", "create", "modify", "delete", "manage_permissions", "apply_tag", "remove_tag", "view_tag"],
    "interactive_services_providers": ["delete", "create", "modify", "manage_interactive_services_provider_integration"],
    "invoice_billable_items": ["create", "modify", "delete"],
    "lead_companies": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "lead_company_campaigns": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "lead_company_campaign_tags": ["create", "modify", "delete"],
    "lead_company_refund_receipts": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "lead_company_files": ["view", "create", "modify", "delete"],
    "lead_company_invoices": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "lead_company_quotes": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "lead_company_tags": ["create", "modify", "delete"],
    "lead_company_to_identity_autoaction_conditions": ["view", "create", "modify", "delete"],
    "lead_contacts": ["view", "create"],
    "lead_statuses": ["create", "modify", "delete"],
    "locations": ["create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "location_tags": ["create", "modify", "delete"],
    "logs": ["view", "create", "modify", "delete"],
    "logins": ["view"],
    "marketing_groups": ["create", "modify", "delete"],
    "mmrs": ["create", "modify", "delete"],
    "monitoring_companies": ["create", "modify", "delete", "manage_central_station_integration", "central_station_invoke_generic", "view_adt_monitoring_company_financial_reports", "view_adt_monitoring_company_operational_reports"],
    "note_types": ["create", "modify", "delete"],
    "notification_subscriptions": ["modify"],
    "customer_order_information": ["view", "modify"],
    "package_equipment": ["create", "modify", "delete"],
    "packages": ["create", "modify", "delete"],
    "payment_gateway_transactions": ["view"],
    "pending_equipment_line_items": ["view", "create", "modify", "delete"],
    "people_transactions": ["view"],
    "reschedule_reasons": ["create", "modify", "delete"],
    "customer_ssns": ["view", "modify"],
    "customer_system_information": ["view", "modify"],
    "contract_terms": ["create", "modify", "delete"],
    "customer_tasks": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag", "apply_template"],
    "customer_task_tags": ["create", "modify", "delete"],
    "user_files": ["view", "create", "modify", "delete"],
    "user_hierarchy_titles": ["create", "modify", "delete"],
    "user_hierarchy_trees": ["view", "create", "modify", "delete"],
    "user_rep_numbers": ["view", "create", "modify", "delete"],
    "user_tags": ["create", "modify", "delete"],
    "user_tech_restrictions": ["view", "create", "modify", "delete"],
    "users": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag", "manage_single_sign_on", "unlock_users_login"],
    "zip_relations": ["view", "create", "delete"],
    "order_templates": ["create", "modify", "delete"],
    "order_templates_field_defaults": ["create", "modify", "delete"],
    "recurring_invoices": ["view", "create", "modify", "delete"],
    "user_hr_settings": ["view", "modify"],
    "user_password_settings": ["view", "modify"],
    "user_preferences": ["view", "modify"],
    "user_tech_settings": ["view", "modify"],
    "user_inventory_settings": ["view", "modify", "perform_equipment_transfer"],
    "customer_abort_codes": ["view", "modify"],
    "comments": ["view", "create", "modify", "delete"],
    "global_settings": ["view", "modify"],
    "global_settings_invoice_settings": ["modify"],
    "user_messages": ["send_message"],
    "user_stats": ["view"],
    "government_regulations": ["view"],
    "tech_scheduling_profiles": ["modify"],
    "equipment_transfers": ["view", "modify", "delete"],
    "equipment_spillage": ["view", "create", "modify", "delete", "view_tag", "remove_tag", "apply_tag"],
    "equipment_purchase_requisitions": ["view", "create", "modify", "delete", "view_tag", "remove_tag", "apply_tag"],
    "equipment_purchase_orders": ["view", "create", "modify", "delete", "view_tag", "remove_tag", "apply_tag"],
    "equipment_return_merchandise_authorizations": ["view", "create", "modify", "delete", "view_tag", "remove_tag", "apply_tag"],
    "workflow_configs": ["modify", "create", "delete"],
    "templates": ["modify", "create", "delete"],
    "template_field_defaults": ["modify", "create", "delete"],
    "work_order_tags": ["create", "modify", "delete"],
    "work_orders": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag", "apply_template"],
    "user_to_locations": ["create", "delete", "view"],
    "directory_users": ["view"],
    "calendar_users": ["view"],
    "processing_gateways": ["view", "modify"],
    "account_classes": ["create", "modify", "delete"],
    "credit_bureau_configs": ["manage"],
    "location_license_transactions": ["view", "create", "modify"],
    "location_licenses": ["view", "create", "modify", "delete"],
    "company_license_transactions": ["view", "create", "modify"],
    "company_licenses": ["view", "create", "modify", "delete"],
    "user_license_transactions": ["view", "create", "modify"],
    "user_licenses": ["view", "create", "modify", "delete"],
    "equipment_kits": ["create", "modify", "delete"],
    "equipment_kit_items": ["create", "modify", "delete"],
    "user_hierarchies": ["manage", "view"],
    "customer_list_settings": ["manage"],
    "customer_lead_companies": ["view"],
    "monitoring_company_configs": ["manage"],
    "leads": ["view", "modify", "create", "delete", "apply_tag", "remove_tag", "view_tag", "central_station_invoke_generic", "convert_to_customer", "modify_lead_disposition"],
    "lead_birthdates": ["view", "modify"],
    "lead_ssns": ["view", "modify"],
    "lead_credit_checks": ["view", "create", "delete", "view_print_image"],
    "lead_quotes": ["view", "modify", "create", "delete", "apply_tag", "remove_tag", "view_tag"],
    "customer_lead_information": ["view"],
    "lead_information": ["view", "modify"],
    "customer_lead_source_information": ["view", "modify"],
    "lead_source_information": ["view", "modify"],
    "interactive_services_provider_configs": ["manage"],
    "electronic_document_provider_configs": ["manage"],
    "customer_payable_line_items": ["view", "create", "modify", "delete"],
    "dashboard_cards_top_sales_reps": ["view_company"],
    "dashboard_cards_tech_schedule_appointments": ["view_company", "view_me"],
    "dashboard_cards_sales_numbers": ["view_company"],
    "sales_tax_exemption_settings": ["manage"],
    "customer_dashboard_cards_contract_terms": ["view"],
    "customer_dashboard_cards_central_station": ["view"],
    "customer_dashboard_cards_interactive_service_providers": ["view"],
    "customer_dashboard_cards_tasks": ["view"],
    "customer_dashboard_cards_credit_score": ["view"],
    "dashboard_cards_appointments_for_today": ["view_me", "view_company"],
    "dashboard_cards_tasks_due_today": ["view_me", "view_company"],
    "dashboard_cards_work_orders_for_today": ["view_me"],
    "dashboard_cards_at_risk_entries_for_today": ["view_me", "view_company"],
    "dashboard_cards_recent_at_risk_entries": ["view_me", "view_company"],
    "dashboard_cards_home_tasks": ["view_me", "view_company"],
    "dashboard_cards_work_orders": ["view_me", "view_company"],
    "dashboard_cards_at_risk_entries": ["view_me", "view_company"],
    "dashboard_cards_today_sales": ["view_me"],
    "dashboard_cards_today_appointments": ["view_company"],
    "electronic_document_envelopes": ["modify", "delete", "create", "view"],
    "customer_dashboard_cards_last_interaction": ["view"],
    "dashboard_cards_open_purchase_orders": ["view_company"],
    "dashboard_cards_open_purchase_requisitions": ["view_company"],
    "dashboard_cards_open_spillage_reports": ["view_company"],
    "dashboard_cards_locked_spillage_reports": ["view_company"],
    "dashboard_cards_open_rmas": ["view_company"],
    "dashboard_cards_open_transfers": ["view_company"],
    "customer_payable_payments": ["view", "create", "modify", "delete"],
    "fundings": ["view", "create", "modify", "delete"],
    "funding_transactions": ["view", "create", "modify", "delete"],
    "funding_progress_status_logs": ["view"],
    "funding_transaction_types": ["view", "create", "modify", "delete"],
    "consumer_financing_applicants": ["view", "create", "modify", "delete"],
    "consumer_financing_applications": ["view", "create", "delete", "modify"],
    "consumer_financing_lenders": ["view", "delete", "create", "modify"],
    "consumer_financing_lender_configs": ["manage"],
    "dashboard_cards_open_funding_entries": ["view_company"],
    "lead_messages": ["view", "create"],
    "payable_payee_levels": ["view", "create", "modify", "delete"],
    "customer_payables": ["view", "create", "modify", "delete", "view_tag", "remove_tag", "apply_tag"],
    "customer_dashboard_cards_profit_and_losses": ["view"],
    "tech_scheduling_profile_configs": ["manage"],
    "customer_dashboard_cards_payable_summaries": ["view"],
    "lead_notes": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "lead_files": ["view", "create", "modify", "delete"],
    "payable_conditions": ["view", "create", "modify", "delete"],
    "dashboard_cards_my_day": ["view_me"],
    "dashboard_cards_work_orders_due_today": ["view_me", "view_company"],
    "dashboard_cards_tasks": ["view"],
    "funding_types": ["create", "modify", "delete"],
    "customer_accounting_information": ["create", "modify", "delete"],
    "invoice_statements": ["view", "create", "modify", "delete"],
    "customer_billing_bill_bank_numbers": ["view_partial_bank_number", "view", "modify"],
    "customer_payment_types": ["create", "delete", "modify"],
    "lead_dispositions": ["delete", "modify", "create", "view"],
    "equipment_tags": ["create", "modify", "delete"],
    "invoice_tags": ["create", "modify", "delete"],
    "pdf_default_settings": ["view", "modify", "delete"],
    "pdf_invoice_settings": ["view", "modify", "delete"],
    "pdf_quote_settings": ["view", "modify", "delete"],
    "pdf_statement_settings": ["view", "modify", "delete"],
    "pdf_credit_memo_settings": ["view", "modify", "delete"],
    "pdf_receipt_settings": ["view", "modify", "delete"],
    "sales_scheduling_profiles": ["modify"],
    "sales_scheduling_profile_configs": ["manage"],
    "udf_sections": ["create", "modify", "delete"],
    "admin_udfs": ["create", "modify", "delete"],
    "customer_udfs": ["view", "create", "modify", "delete"],
    "lead_udfs": ["view", "create", "modify", "delete"],
    "customer_credit_memos": ["view", "create", "modify", "delete", "apply_tag", "remove_tag", "view_tag"],
    "consumer_financing_applicants_additional_incomes": ["view", "create", "modify", "delete"],
    "payroll_columns": ["create", "modify", "delete"],
    "payroll_role_functions": ["create", "modify", "delete"],
    "payroll_role_function_levels": ["create", "modify", "delete"],
    "payroll_function_method_parameters": ["view", "create", "modify", "delete"],
    "payroll_column_calculations": ["create", "modify", "delete"],
    "quickbooks_integrations": ["view"],
    "quickbooks_integration_configs": ["manage"],
    "sync_object_to_providers": ["view", "sync_objects"],
    "custom_sql_reports": ["run"],
    "customer_reports": ["create", "modify", "delete", "run"],
    "edit_data_customer_reports": ["edit_data"],
    "notes_ticket_reports": ["create", "modify", "delete", "run"],
    "edit_data_notes_ticket_reports": ["edit_data"],
    "customers_payable_reports": ["create", "modify", "delete", "run"],
    "edit_data_customers_payable_reports": ["edit_data"],
    "customers_equipment_reports": ["create", "modify", "delete", "run"],
    "edit_data_customers_equipment_reports": ["edit_data"],
    "lead_customer_transaction_reports": ["create", "modify", "delete", "run"],
    "edit_data_lead_customer_transaction_reports": ["edit_data"],
    "customer_payment_reports": ["create", "modify", "delete", "run"],
    "edit_data_customer_payment_reports": ["edit_data"],
    "customer_communication_reports": ["create", "modify", "delete", "run"],
    "edit_data_customer_communication_reports": ["edit_data"],
    "tech_reschedule_reports": ["create", "modify", "delete", "run"],
    "edit_data_tech_reschedule_reports": ["edit_data"],
    "customers_at_risk_reports": ["create", "modify", "delete", "run"],
    "edit_data_customers_at_risk_reports": ["edit_data"],
    "lead_reports": ["create", "modify", "delete", "run"],
    "edit_data_lead_reports": ["edit_data"],
    "payment_gateway_transaction_reports": ["create", "modify", "delete", "run"],
    "edit_data_payment_gateway_transaction_reports": ["edit_data"],
    "invoices_quote_reports": ["create", "modify", "delete", "run"],
    "edit_data_invoices_quote_reports": ["edit_data"],
    "pending_equipment_reports": ["create", "modify", "delete", "run"],
    "edit_data_pending_equipment_reports": ["edit_data"],
    "user_reports": ["create", "modify", "delete", "run"],
    "edit_data_user_reports": ["edit_data"],
    "license_reports": ["create", "modify", "delete", "run"],
    "edit_data_license_reports": ["edit_data"],
    "funding_reports": ["create", "modify", "delete", "run"],
    "edit_data_funding_reports": ["edit_data"],
    "funding_transaction_reports": ["create", "modify", "delete", "run"],
    "edit_data_funding_transaction_reports": ["edit_data"],
    "equipment_purchase_order_tags": ["create", "modify", "delete"],
    "equipment_purchase_requisition_tags": ["create", "modify", "delete"],
    "equipment_return_merchandise_authorization_tags": ["create", "modify", "delete"],
    "equipment_spillage_tags": ["create", "modify", "delete"],
    "inventory_users": ["view"],
    "on_site_information": ["create", "view", "modify", "delete"],
    "customer_contact_udfs": ["view", "create", "modify", "delete"],
    "electronic_document_providers": ["create", "modify", "delete"],
    "customer_equipment_udfs": ["view", "create", "modify", "delete"],
    "work_order_reports": ["create", "modify", "delete", "run"],
    "edit_data_work_order_reports": ["edit_data"],
    "customer_payable_tags": ["create", "modify", "delete"],
    "customer_duplicate_defaults": ["modify"],
    "customer_contact_defaults": ["modify"],
    "customer_information_defaults": ["modify"],
    "location_udfs": ["view", "create", "modify", "delete"],
    "lead_information_defaults": ["modify"],
    "lead_details_defaults": ["modify"],
    "equipment_udfs": ["view", "create", "modify", "delete"],
    "user_udfs": ["view", "create", "modify", "delete"],
    "tech_schedule_udfs": ["view", "create", "modify", "delete"],
    "dashboard_cards_sales": ["view_company", "view_me"],
    "customer_equipment_tags": ["create", "modify", "delete"],
    "customer_task_reports": ["create", "modify", "delete", "run"],
    "edit_data_customer_task_reports": ["edit_data"],
    "scheduling_profile_defaults": ["modify"],
    "appointment_type_defaults": ["modify"],
    "message_logs": ["view"],
    "lead_company_udfs": ["view", "create", "modify", "delete"],
    "dashboard_cards_payable_entries": ["view_me", "view_company"],
    "dashboard_cards_average_monthly_charge": ["view_company", "view_me"],
    "global_settings_recurring_invoice_settings": ["modify"]
  };
  _exports.availableGrants = availableGrants;
});