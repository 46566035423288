define("securitytrax-ember-ui/components/multi-step-form/forms/system/stages/settings/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/stages/stages-base", "ember-concurrency", "moment"], function (_exports, _stagesBase, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Settings = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      panelTestFunctionalityEnabled: this.panelTestFunctionalityEnabledTask.perform(),
      durationOptions: this.durationOptionsTask.perform()
    });
    Ember.setProperties(this, hashedData);
    this.resetFormData();
    this.deviceInTestStatus = yield this.deviceInTestStatusTask.perform();
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    var functionalityAvailable = false;

    try {
      var panelTestFunctionalityAvailable = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getPanelTestFunctionalityAvailable'
      });
      functionalityAvailable = panelTestFunctionalityAvailable.panel_test_functionality_available;
    } catch (e) {
      return false;
    }

    return functionalityAvailable;
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    var hourName = ' ' + this.intl.t('pages.administration.organization.officeLocations.hours');
    var singleHourName = ' ' + this.intl.t('pages.administration.organization.officeLocations.hour');
    var minuteName = ' ' + this.intl.t('pages.customers.system.order.minutes');
    var data = yield this.centralStationService.getData({
      model: this.args.monitoringCompany,
      method: 'stages__getLoadableLists'
    });
    this.durationHours, this.wholeHourDurationOptions;
    this.durationMinutes, this.quarterlyMinuteDurationOptions;

    for (var list of data.loadable_lists) {
      switch (list.type) {
        case 'test_mode_hours':
          this.durationHours = list.items.map(item => {
            return {
              name: Number(item.name) + ' ' + (item.name === '01' ? singleHourName : hourName),
              value: item.name
            };
          });
          break;

        case 'test_mode_minutes':
          this.durationMinutes = list.items.map(item => {
            return {
              name: Number(item.name) + ' ' + minuteName,
              value: item.name
            };
          });
          break;

        case 'test_mode_categories':
          this.testCategoryOptions = list.items;
          this.defaultTestCategoryId = list.items.find(category => category.is_default).id;
          break;
      }
    }
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    var devicesInTestMode = [];

    if (this.panelTestFunctionalityEnabled) {
      try {
        devicesInTestMode = yield this.centralStationService.getData({
          model: this.args.customer,
          method: 'stages__getDeviceTestInEffectList'
        });

        if (devicesInTestMode.device_test_in_effect_list_result.length > 0) {
          this.testExpireDate = (0, _moment.default)(devicesInTestMode.device_test_in_effect_list_result[0].test_expire_date).format('MM/DD/YYYY hh:mm:ss A');
          this.systemInTestMode = true;
          return this.intl.t('pages.customers.system.order.in_test');
        } else {
          this.systemInTestMode = false;
          return this.intl.t('pages.customers.system.order.not_in_test');
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        return this.intl.t('pages.customers.system.order.not_in_test');
      }
    }
  }), _dec19 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var messages = [];
    var formattedObjects = Ember.A();
    var attributes = this.attributes;
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        testModeHours: attributes['test_mode_hours'],
        testModeMinutes: attributes['test_mode_minutes'],
        testCategory: attributes['test_category']
      },
      extraValidationData: {
        noValidate: !this.setToTest || this.systemInTestMode ? ['stages-testModeHours', 'stages-testModeMinutes'] : []
      },
      filePath: 'central-station/stages-modify'
    }));
    yield multiStepForm.validateList.perform(formattedObjects);
    var hashObject = {};

    if (this.category && !this.setToTest) {
      hashObject.setOos = this.setOutOfServiceCategoryTask.perform();
    }

    if (this.setToTest) {
      hashObject['setTest'] = this.setTestModeTask.perform();
    }

    var response = yield (0, _emberConcurrency.hash)(hashObject);
    Object.keys(response).forEach(key => {
      messages.concat(response[key].responsePayload.data.messages);
    });
    buttonPromise.then(() => {
      this.args.customer.reload();
      this.resetFormData();
      this.deviceInTestStatusTask.perform();
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');

      if (messages.length > 0) {
        this.stNotificationManager.addWarningMessages(messages, {
          notificationNamespace: 'modal'
        });
      }
    });
  }), _dec20 = (0, _emberConcurrency.task)(function* () {
    var attributes = this.attributes;
    this.attributes.action = this.systemInTestMode ? 'cancel_test_mode' : 'set_test_mode';

    try {
      var retVal = yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'stages__modifyDeviceTest',
        payload: attributes
      });
      this.setTestModeFormStarted = false;
      this.systemInTestMode = !this.systemInTestMode;
      this.deviceInTestStatus = this.systemInTestMode ? this.intl.t('pages.customers.system.order.in_test') : this.intl.t('pages.customers.system.order.not_in_test');
      return retVal;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec21 = (0, _emberConcurrency.task)(function* () {
    try {
      var category = yield this.category;
      return yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'stages__modifyDeviceInOutService',
        payload: {
          'oos_cat': category ? category === 'active' ? -1 : category : null
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec22 = Ember._action, _dec23 = Ember._action, (_class = (_temp = class Settings extends _stagesBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "attributes", _descriptor2, this);

      _initializerDefineProperty(this, "setToTest", _descriptor3, this);

      _initializerDefineProperty(this, "durationHours", _descriptor4, this);

      _initializerDefineProperty(this, "durationMinutes", _descriptor5, this);

      _initializerDefineProperty(this, "testExpireDate", _descriptor6, this);

      _initializerDefineProperty(this, "systemInTestMode", _descriptor7, this);

      _initializerDefineProperty(this, "category", _descriptor8, this);

      _initializerDefineProperty(this, "setTestModeFormStarted", _descriptor9, this);

      _initializerDefineProperty(this, "testCategoryOptions", _descriptor10, this);

      _initializerDefineProperty(this, "panelTestFunctionalityEnabled", _descriptor11, this);

      _initializerDefineProperty(this, "durationOptions", _descriptor12, this);

      _initializerDefineProperty(this, "deviceInTestStatus", _descriptor13, this);

      _initializerDefineProperty(this, "defaultTestCategoryId", _descriptor14, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor15, this);

      _initializerDefineProperty(this, "panelTestFunctionalityEnabledTask", _descriptor16, this);

      _initializerDefineProperty(this, "durationOptionsTask", _descriptor17, this);

      _initializerDefineProperty(this, "deviceInTestStatusTask", _descriptor18, this);

      _initializerDefineProperty(this, "modifyAccountTask", _descriptor19, this);

      _initializerDefineProperty(this, "setTestModeTask", _descriptor20, this);

      _initializerDefineProperty(this, "setOutOfServiceCategoryTask", _descriptor21, this);

      this.initDataTask.perform();
    }

    get wholeHourDurationOptions() {
      var hourName = ' ' + this.intl.t('pages.administration.organization.officeLocations.hours');
      var singleHourName = ' ' + this.intl.t('pages.administration.organization.officeLocations.hour');
      return [Ember.Object.create({
        name: '0 ' + hourName,
        value: '00'
      }), Ember.Object.create({
        name: '1 ' + singleHourName,
        value: '01'
      }), Ember.Object.create({
        name: '2 ' + hourName,
        value: '02'
      }), Ember.Object.create({
        name: '3 ' + hourName,
        value: '03'
      }), Ember.Object.create({
        name: '4 ' + hourName,
        value: '04'
      }), Ember.Object.create({
        name: '5 ' + hourName,
        value: '05'
      })];
    }

    get quarterlyMinuteDurationOptions() {
      var minuteName = ' ' + this.intl.t('pages.customers.system.order.minutes');
      return [Ember.Object.create({
        name: '0 ' + minuteName,
        value: '00'
      }), Ember.Object.create({
        name: '15 ' + minuteName,
        value: '15'
      }), Ember.Object.create({
        name: '30 ' + minuteName,
        value: '30'
      }), Ember.Object.create({
        name: '45 ' + minuteName,
        value: '45'
      })];
    }

    resetFormData() {
      this.setTestCategory(this.defaultTestCategoryId);
      this.setToTest = false;
    }

    setTestCategory(testCategoryValue) {
      var testCategory = this.testCategoryOptions.find(category => category.id === testCategoryValue);

      if (testCategory) {
        this.attributes = {
          'test_mode_hours': testCategory.default_duration_hours,
          'test_mode_minutes': testCategory.default_duration_minutes,
          'test_category': testCategory.id
        };
      }
    }

    buttons() {
      this.args.multiStepForm.registerStepData('settings', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.modifyAccountTask.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary stages-settings-create-account-button'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "setToTest", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "durationHours", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "durationMinutes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "testExpireDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "systemInTestMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "setTestModeFormStarted", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "testCategoryOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "panelTestFunctionalityEnabled", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "durationOptions", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "deviceInTestStatus", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "defaultTestCategoryId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "panelTestFunctionalityEnabledTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "durationOptionsTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "deviceInTestStatusTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "modifyAccountTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "setTestModeTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "setOutOfServiceCategoryTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setTestCategory", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "setTestCategory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = Settings;
});